import Vue from 'vue';
import axios from 'axios';
import {
  LOCATION_DELIMITER,
  errorsFromRanges,
  hasErrors,
  hasRequired,
  makeJobRanges,
  makeManualRange,
  rangesToAddErrorsInit,
  rangesToAddInit,
  serializeRanges,
  validRanges,
} from '@/store/services/salaryBoard';
import { URL_BASE, createUuid, displayCount } from '@/helpers';
import { cloneDeep, isEmpty, isEqual } from 'lodash-es';

const SALARY_RANGE_API = `${URL_BASE}/api/salary-range`;

const state = () => ({
  manualRanges: [],
  isLoadingRanges: false,
  isLoadingJobRanges: false,
  jobs: [],
  isLoadingJobs: false,
  editingJobId: null,
  rangesToAdd: rangesToAddInit(),
  rangesToAddErrors: rangesToAddErrorsInit(),
  isPublishingRanges: false,
});

const getters = {
  manualRanges: (state) => state.manualRanges,
  manualRangesByJobId: (state, getters) => {
    return getters.manualRanges.reduce((acc, range) => {
      const job = (acc[range.job.id] = acc[range.job.id] || []);
      job.push(range);
      return acc;
    }, {});
  },
  locationGroupedRanges: (state, getters) => {
    const makeSalaryId = (range) => `${range.job.id}-${range.salary_low}-${range.salary_high}`;
    const uuidBySalaryId = {};

    return getters.manualRanges.reduce((acc, range) => {
      const job = (acc[range.job.id] = acc[range.job.id] || makeJobRanges(range.job));
      const salaryId = makeSalaryId(range);
      const uuid = uuidBySalaryId[salaryId];
      if (uuid) {
        job.ranges[uuid].locations += `${LOCATION_DELIMITER} ${range.location}`;
      } else {
        const newUuid = (uuidBySalaryId[salaryId] = createUuid());
        job.ranges[newUuid] = makeManualRange(range);
      }
      return acc;
    }, {});
  },
  isLoadingRanges: (state) => state.isLoadingRanges,
  isLoadingJobRanges: (state) => state.isLoadingJobRanges,
  jobs: (state) => state.jobs,
  isLoadingJobs: (state) => state.isLoadingJobs,
  rangesToAdd: (state) => state.rangesToAdd,
  rangesToAddErrors: (state) => errorsFromRanges(state.rangesToAdd, state.rangesToAddErrors),
  rangeError: (state, getters) => (uuid, attr) => getters.rangesToAddErrors?.ranges?.[uuid]?.[attr],
  isPublishingRanges: (state) => state.isPublishingRanges,
  validRangesToAdd: (state, getters) => {
    return (
      !isEmpty(getters.rangesToAdd.ranges) &&
      !hasErrors(getters.rangesToAddErrors) &&
      hasRequired(getters.rangesToAdd) &&
      validRanges(getters.rangesToAdd.ranges)
    );
  },
  editingJobId: (state) => state.editingJobId,
  isEditing: (state, getters) => getters.editingJobId !== null,
  unsavedChanges: (state, getters) => {
    return !getters.isEditing
      ? null
      : !isEqual(
          serializeRanges(getters.locationGroupedRanges[getters.editingJobId]),
          serializeRanges(getters.rangesToAdd)
        );
  },
  getRangeCount: (state, getters) => {
    return (jobId) => {
      const count = Object.keys(getters.locationGroupedRanges[jobId]?.ranges || {}).length;
      return displayCount(count, 'Range', 'Ranges');
    };
  },
};

const mutations = {
  setManualRanges(state, ranges) {
    state.manualRanges = ranges;
  },
  updateManualRanges(state, ranges) {
    const updatedIds = ranges.map(({ id }) => id);
    state.manualRanges = [...state.manualRanges.filter(({ id }) => !updatedIds.includes(id)), ...ranges];
  },
  setIsLoadingRanges(state, isLoadingRanges) {
    state.isLoadingRanges = isLoadingRanges;
  },
  setIsLoadingJobRanges(state, isLoading) {
    state.isLoadingJobRanges = isLoading;
  },
  setJobs(state, jobs) {
    state.jobs = jobs;
  },
  setIsLoadingJobs(state, isLoading) {
    state.isLoadingJobs = isLoading;
  },
  setRangesToAdd(state, job) {
    state.rangesToAdd = cloneDeep(job);
  },
  setRangesToAddRange(state, { uuid, range }) {
    Vue.set(state.rangesToAdd.ranges, uuid, range);
  },
  setRangesToAddError(state, { attr, message }) {
    Vue.set(state.rangesToAddErrors, attr, message);
  },
  setRangesToAddRangeError(state, { uuid, attr, message }) {
    !state.rangesToAddErrors?.[uuid]
      ? Vue.set(state.rangesToAddErrors, uuid, { [attr]: message })
      : Vue.set(state.rangesToAddErrors[uuid], attr, message);
  },
  setEditingJobId(state, jobId) {
    state.editingJobId = jobId;
  },
  clearRangesToAddRangeError(state, { uuid, attr }) {
    delete state.rangesToAddErrors?.[uuid]?.[attr];
  },
  setJob(state, { attr, value }) {
    state.rangesToAdd[attr] = value;
  },
  setRange(state, { uuid, attr, value }) {
    state.rangesToAdd.ranges[uuid][attr] = value;
  },
  setIsPublishingRanges(state, value) {
    state.isPublishingRanges = value;
  },
  resetRangesAndErrors(state) {
    state.rangesToAdd = rangesToAddInit();
    state.rangesToAddErrors = rangesToAddErrorsInit();
    state.editingJobId = null;
  },
  deleteRangeToAdd(state, rangeId) {
    delete state.rangesToAdd.ranges[rangeId];
    state.rangesToAdd = { ...state.rangesToAdd };
  },
};

const actions = {
  async fetchJobRanges({ commit }, job_id) {
    commit('setIsLoadingJobRanges', true);
    try {
      const { data } = await axios.get(`${SALARY_RANGE_API}/manual-salary-ranges/range`, { params: { job_id } });
      commit('updateManualRanges', data);
    } catch (error) {
      console.error(error);
    }
    commit('setIsLoadingJobRanges', false);
  },
  async fetchManualRanges({ commit }) {
    try {
      commit('setIsLoadingRanges', true);
      commit('setManualRanges', []);
      const { data } = await axios.get(`${SALARY_RANGE_API}/manual-salary-ranges`);
      commit('setManualRanges', data);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setIsLoadingRanges', false);
    }
  },
  async fetchJobs({ commit }) {
    commit('setIsLoadingJobs', true);
    try {
      const { data } = await axios.get(`${SALARY_RANGE_API}/salary-board-jobs`);
      // SB-85: Sorting jobs alphabetically based on name
      data.sort((a, b) => a.name.localeCompare(b.name));
      commit('setJobs', data);
    } catch (error) {
      console.error(error);
    }
    commit('setIsLoadingJobs', false);
  },
  async publishRanges({ commit, getters }) {
    if (!getters.validRangesToAdd) {
      return;
    }
    commit('setIsPublishingRanges', true);
    try {
      await axios.post(`${SALARY_RANGE_API}/manual-salary-ranges`, serializeRanges(getters.rangesToAdd));
      commit('setIsPublishingRanges', false);
    } catch (error) {
      console.error('Error caught while saving salary board changes: ', error);
      commit('setIsPublishingRanges', false);
      return ['Could not publish', 'Something went wrong.', 'error'];
    }
  },
  async deleteManualRanges({ dispatch, commit }, payload) {
    try {
      await axios.delete(`${SALARY_RANGE_API}/manual-salary-ranges/delete_range/?job_id=${payload}`);
      dispatch('fetchJobs');
      dispatch('fetchManualRanges');
      return ['Awesome! Salary ranges deleted successfully.', 'success'];
    } catch (error) {
      console.error('Unable to delete salary ranges');
      return ['Something went wrong. Unable to delete salary ranges.', 'error'];
    }
  },
  addEmptyJobRange({ commit }) {
    const uuid = createUuid();
    const range = makeManualRange();
    commit('setRangesToAddRange', { uuid, range });
    return uuid;
  },
  async copyLink(_, link) {
    try {
      await navigator.clipboard.writeText(link);
      return ['Link copied to clipboard. Paste the link to your job posting.', 'success'];
    } catch {
      return ['Something went wrong. Unable to copy link to clipboard.', 'error'];
    }
  },
  editExistingRange({ commit, getters }, jobId) {
    const ranges = getters.locationGroupedRanges[jobId];
    if (ranges) {
      commit('setEditingJobId', jobId);
      commit('setRangesToAdd', ranges);
    }
  },
  getRangeIfMissing({ dispatch, getters }, job_id) {
    !getters.manualRangesByJobId[job_id] && dispatch('fetchJobRanges', job_id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
